import React from 'react'
import Footer from '../Footer'
import NavigationBar from '../NavBar'
import CookieConsent from 'react-cookie-consent'

export default function MasterLayout(props) {
    return (
        <div className="pageContainer">
            <header className="headerContainer">
                <NavigationBar />
            </header>
            <div className="contentWrap body-container">
                {props.children}
            </div>
            <footer>
                <Footer />
            </footer>
            <CookieConsent 
                style={{background: "hsla(152, 68%, 32%, 0.500)"}}
                buttonStyle={{background: "#1a8754", color: "white"}}
                buttonText="Accept">
                    This website uses cookies to ensure you get the best experience on our website.&nbsp;
                    <a href="https://bcis.co.uk/cookie-policy/">Learn more</a>
            </CookieConsent>
        </div>
    )
}