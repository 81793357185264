import React, { useEffect } from "react";
import ReactGA from "react-ga";
import { useQuery } from "react-query";
import MasterLayout from "./components/layout/MasterLayout";
import TextBlock from "./components/TextBlock";
import DocumentList from "./components/DocumentList";
import { fetchDocumentsPageContent } from "./utils/cmsData";

export default function Documents() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  const { data, error, isError, isLoading } = useQuery(
    "documents",
    fetchDocumentsPageContent
  );

  if (isLoading) return "Loading...";
  if (isError) return { error };

  return (
    <MasterLayout>
      {data && data.MainHeading && data.MainHeading.length > 0 && (
        <h1>{data.MainHeading}</h1>
      )}
      {data &&
        data.IntroductionContent &&
        data.IntroductionContent.length > 0 && (
          <TextBlock content={data.IntroductionContent} />
        )}
      {data && data.Documents && data.Documents.length > 0 && (
        <DocumentList data={data.Documents} archived={false} />
      )}
      {data &&
        data.MainHeading &&
        data.MainHeading.length > 0 &&
        data.Documents.some((e) => e.Archived === true) && (
          <>
            <h1 className="archiveHeader">Archive documents</h1>
            <DocumentList data={data.Documents} archived={true} />
          </>
        )}
    </MasterLayout>
  );
}
