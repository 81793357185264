import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Logo from './Logo'
import { Card } from 'react-bootstrap'

export default function CollaboratorLogos(props) {

    const cmsEndPoint = process.env.REACT_APP_CMS_END_POINT

    function renderLogos() {

        const finalJSX = []
        let columns = []

        props.logos.forEach((logo, index) => {
            columns.push(
                <Col className="collaboratorLogoCol" key={`col-${index.toString()}`}>
                    <Logo
                        url={`${cmsEndPoint}${logo.ImageAsset.url}`}
                        altText={logo.AlternativeText}
                        key={index.toString()}
                    />
                </Col>
            )
            if ((index + 1) % 3 === 0) {
                finalJSX.push(<Row className="collaboratorLogoRow" key={`col-${index.toString()}`}>{columns}</Row>)
                columns = []
            }
        })

        if (columns.length > 0) {
            finalJSX.push(<Row className="collaboratorLogoRow" key="collaboratorLogosFinalRow">{columns}</Row>)
        }

        return finalJSX
    }

    return (
        <Container>
            {/* <Card className="collaboratorLogosCard"> */}
            <Card className="cardNoBorder">
                {renderLogos()}
            </Card>
        </Container >
    )
}