import React, { useEffect } from 'react'
import ReactGA from 'react-ga'
import { useQuery } from 'react-query'
import ZohoForm from './components/ZohoForm'
import TextBlock from './components/TextBlock'
import { fetchKeepInformedPageContent } from './utils/cmsData'
import MasterLayout from './components/layout/MasterLayout'

export default function KeepInformed(props) {

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search)
    }, [])

    const { data, error, isError, isLoading } = useQuery('keepInformed', fetchKeepInformedPageContent)

    if (isLoading) return "Loading..."
    if (isError) return { error }

    return (
        <MasterLayout>
            {
                data && data.MainHeading && data.MainHeading.length > 0 &&
                <h1>{data.MainHeading}</h1>
            }
            {
                data && data.IntroductionContent && data.IntroductionContent.length > 0 &&
                <TextBlock content={data.IntroductionContent} />
            }
            {
                data && data.ContactForm && data.ContactForm.DivId.length > 0 &&
                <ZohoForm
                    divId={data.ContactForm.DivId}
                    iframeSrc={data.ContactForm.iFrameSource}
                    iframeStyle={data.ContactForm.iFrameStyle}
                    formScript={data.ContactForm.Javascript}
                />
            }
            {
                data && data.PrivacyStatement && data.PrivacyStatement.length > 0 &&
                <div className="contactFormPrivacyStatement">
                    <TextBlock content={data.PrivacyStatement} />
                </div>
            }
        </ MasterLayout>
    )
}