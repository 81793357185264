import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import ReactGA from 'react-ga'
import { useQuery } from 'react-query'
import MasterLayout from './components/layout/MasterLayout'
import { fetchInsightsPageContent } from './utils/cmsData'
import TextBlock from './components/TextBlock'

export default function Article() {

    const cmsEndPoint = process.env.REACT_APP_CMS_END_POINT

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search)
    }, [])

    const { data, error, isError, isLoading } = useQuery('insights', fetchInsightsPageContent)
    const { id } = useParams()

    if (isLoading) return "Loading..."
    if (isError) return { error }

    function renderArticle() {
        // Find the article within the data that matches the ID passed
        const articleContent = data.InsightArticles.filter(article => article.id.toString() === id)
        const finalJSX = []

        finalJSX.push(<h1>{articleContent[0].Title}</h1>)
        finalJSX.push(<p>Date Published: {new Date(articleContent[0].DatePublished).toDateString()}</p>)
        finalJSX.push(<p>Author: {articleContent[0].Author}</p>)
        finalJSX.push(<img src={`${cmsEndPoint}${articleContent[0].MainImage.url}`} alt={articleContent[0].Title} />)
        finalJSX.push(<TextBlock content={articleContent[0].ArticleContent} />)
        return finalJSX
    }

    return (
        <MasterLayout>
            {renderArticle()}
        </MasterLayout>
    )
}