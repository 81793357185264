import React, { useEffect } from 'react'
import ReactGA from 'react-ga'
import { useQuery } from 'react-query'
import { fetchEntityConsultationContent } from './utils/cmsData'
import MasterLayout from './components/layout/MasterLayout'
import TextBlock from './components/TextBlock'

export default function EntityConsultation(props) {

    const cmsEndPoint = process.env.REACT_APP_CMS_END_POINT

    function handleDownloadClick(docName) {
        ReactGA.event({
            category: 'Documents',
            action: 'Download',
            label: docName
        })
    }

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search)
    }, [])

    const { data, error, isError, isLoading } = useQuery('keepInformed', fetchEntityConsultationContent)

    if (isLoading) return "Loading..."
    if (isError) return { error }

    console.log(data)
    return (
        <MasterLayout>
            {
                data && data.MainHeading && data.MainHeading.length > 0 &&
                <h1>{data.MainHeading}</h1>
            }
            {
                data && data.BodyContent && data.BodyContent.length > 0 &&
                <TextBlock content={data.BodyContent} />
            }
            {
                data && data.LinkToBetaVersion && data.LinkToBetaVersion.length > 0 &&
                <div>
                    Access the BECD entity section here to test it or submit data to the UK NZCBS: <a href={data.LinkToBetaVersion}>Link to Beta version</a>
                </div>
            }
            {
                data && data.UserGuide && data.UserGuide.url.length > 0 &&
                <div>
                    You can find guidance here on how to navigate and use the BECD: <a href={`${cmsEndPoint}${data.UserGuide.url}`} onClick={() => handleDownloadClick("Entity section 2nd Consultation User Guide")}>User Guide</a>
                </div>
            }
            {
                data && data.LinkToQuestionnaire && data.LinkToQuestionnaire.length > 0 &&
                <div>
                    When you are ready, please send us your feedback on the BECD here: <a href={data.LinkToQuestionnaire}>Link to Questionnaire</a>
                </div>
            }
            {
                data && data.ConsultationBrief && data.ConsultationBrief.url.length > 0 &&
                <div>
                    More details about the consultation can be read here: <a href={`${cmsEndPoint}${data.ConsultationBrief.url}`} onClick={() => handleDownloadClick("Entity section 2nd Consultation Consultation Brief")}>Consultation Brief</a>
                </div>
            }
        </MasterLayout>
    )
    
}