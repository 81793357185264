import React, { useEffect } from "react";
import ReactGA from "react-ga";
import { useQuery } from "react-query";
import MasterLayout from "./components/layout/MasterLayout";
import { fetchInsightsPageContent } from "./utils/cmsData";
import TextBlock from "./components/TextBlock";
import InsightList from "./components/InsightList";

export default function Insights() {
  //
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  const { data, error, isError, isLoading } = useQuery(
    "insights",
    fetchInsightsPageContent
  );

  if (isLoading) return "Loading...";
  if (isError) return { error };

  return (
    <MasterLayout>
      {data && data.MainHeading && data.MainHeading.length > 0 && (
        <h1>{data.MainHeading}</h1>
      )}
      {data &&
        data.IntroductionContent &&
        data.IntroductionContent.length > 0 && (
          <TextBlock content={data.IntroductionContent} />
        )}
      {data && data.InsightArticles && data.InsightArticles.length > 0 && (
        <InsightList data={data.InsightArticles} />
      )}
    </MasterLayout>
  );
}
