import React from "react";
import { Link, NavLink } from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
// import DropdownButton from "react-bootstrap/DropdownButton";
// import Dropdown from "react-bootstrap/Dropdown";

export default function NavigationBar() {
  return (
    <Navbar className="navBarPadding" collapseOnSelect bg="light" expand="lg">
      <Navbar.Brand as={Link} to="/" exact="true">
        <img src="/images/becd-nav-logo.png" alt="test" />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="me-auto">
          <Nav.Link as={NavLink} to="/steering-group">
            Steering Group
          </Nav.Link>
          <Nav.Link as={NavLink} to="/working-group">
            Working Group
          </Nav.Link>
          <Nav.Link as={NavLink} to="/documents">
            Documents
          </Nav.Link>
          {/* <Nav.Link as={NavLink} to='/insights'>Insights</Nav.Link> */}
          <Nav.Link as={NavLink} to="/keep-informed">
            Keep Informed
          </Nav.Link>
          <Nav.Link as={NavLink} to="/user-guide">
            User Guide
          </Nav.Link>
          <Nav.Link as={NavLink} to="/user-agreement">
            User Agreement
          </Nav.Link>
          {/* <Nav.Link as={NavLink} to='/entity-section'>Entity section consultation</Nav.Link> */}
        </Nav>
        <Nav.Link href="https://carbon.becd.co.uk/Identity/Account/Register">
          Register
        </Nav.Link>
        <Nav.Link href="https://carbon.becd.co.uk/Identity/Account/Login">
          Login
        </Nav.Link>
        {/* <DropdownButton
          id="dropdown-menu-align-end"
          title="Sign in"
          variant="success"
        >
          <Dropdown.Item href="https://carbon.becd.co.uk">
            Carbon Database
          </Dropdown.Item>
        </DropdownButton> */}
      </Navbar.Collapse>
    </Navbar>
  );
}
