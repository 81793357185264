import React from 'react'
import ReactGA from 'react-ga'
import { useEffect } from 'react'
import { useQuery } from 'react-query'
import MasterLayout from './components/layout/MasterLayout'
import TextBlock from './components/TextBlock'
import Members from './components/Members'
import { fetchSteeringGroupPageContent } from './utils/cmsData'

export default function SteeringGroup() {

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search)
    }, [])

    const { data, error, isError, isLoading } = useQuery('steeringGroup', fetchSteeringGroupPageContent)

    if (isLoading) return "Loading..."
    if (isError) return { error }

    return (
        <MasterLayout>
            {
                data && data.MainHeading && data.MainHeading.length > 0 &&
                <h1>{data.MainHeading}</h1>
            }
            {
                data && data.IntroductionContent && data.IntroductionContent.length > 0 &&
                <TextBlock content={data.IntroductionContent} />
            }
            {
                data && data.SteeringGroupMembers && data.SteeringGroupMembers.length > 0 &&
                <Members data={data.SteeringGroupMembers} />
            }
        </MasterLayout>
    )
}