import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import ArticleItem from './ArticleItem'

export default function InsightList(props) {

    function renderInsightArticles() {

        const finalJSX = []

        props.data.forEach((article, index) => {
            finalJSX.push(
                <Row>
                    <Col key={`col-${index.toString()}`}>
                        <ArticleItem
                            articleId={article.id}
                            articleTitle={article.Title}
                            strapline={article.Strapline}
                            datePublished={article.DatePublished}
                            articleThumbnail={article.MainImage.formats.thumbnail.url}
                            key={props.data.id}
                        />
                    </Col>
                </Row>
            )

        })

        return finalJSX
    }

    return (
        <Container>
            {renderInsightArticles()}
        </Container>
    )

}