import React, { useEffect } from "react";
import { useQuery } from "react-query";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import ReactGA from "react-ga";
import MasterLayout from "./components/layout/MasterLayout";
import Logo from "./components/Logo";
import TextBlock from "./components/TextBlock";
import CollaboratorLogos from "./components/CollaboratorLogos";
import YouTubePlayer from "./components/YouTubePlayer";
import { fetchHomePageContent } from "./utils/cmsData";

ReactGA.initialize("UA-211714996-1");

export default function App() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  const cmsEndPoint = process.env.REACT_APP_CMS_END_POINT;
  const { data, error, isError, isLoading } = useQuery(
    "homepage",
    fetchHomePageContent
  );

  if (isLoading) return "Loading...";
  if (isError) return { error };

  return (
    <MasterLayout>
      {data && data.PageLogo && (
        <Logo
          url={`${cmsEndPoint}${data.PageLogo.ImageAsset.url}`}
          altText={data.PageLogo.AlternativeText}
        />
      )}
      {data && data.MainHeading && data.MainHeading.length > 0 && (
        <h1 className="announcementHeading">{data.MainHeading}</h1>
      )}
      {data && data.YouTubeVideoId && data.YouTubeVideoId.length > 0 && (
        <YouTubePlayer videoId={data.YouTubeVideoId} />
      )}
      {data && data.PageContent && data.PageContent.length > 0 && (
        <TextBlock content={data.PageContent} />
      )}
      {data &&
        data.CollaboratorsHeading &&
        data.CollaboratorsHeading.length > 0 && (
          <h2>{data.CollaboratorsHeading}</h2>
        )}
      {data && data.CollaboratorLogos && data.CollaboratorLogos.length > 0 && (
        <CollaboratorLogos logos={data.CollaboratorLogos} />
      )}
      {data && data.EndorsersHeading && data.EndorsersHeading.length > 0 && (
        <h2>{data.EndorsersHeading}</h2>
      )}
      {data && data.EndorsersLogos && data.EndorsersLogos.length > 0 && (
        <CollaboratorLogos logos={data.EndorsersLogos} />
      )}
      {data &&
        data.KeepMeInformedButtonTitle &&
        data.KeepMeInformedButtonTitle.length > 0 && (
          <div className="informedButton">
            <Link to="/keep-informed">
              <Button variant="success">
                {data.KeepMeInformedButtonTitle}
              </Button>
            </Link>
          </div>
        )}
    </MasterLayout>
  );
}
