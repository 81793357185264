import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

export default function Footer() {
    return (
        <div className="navbar-light bg-light footer">
            <Container className="footerItems">
                <Row>
                    <Col><a href="https://bcis.co.uk/bcis-privacy-policy/">Privacy Policy</a> | <a href="https://bcis.co.uk/cookie-policy/">Cookie Policy</a></Col>
                    <Col className="poweredBy">Powered by &nbsp; <img src="/images/BCIS-Logo.png" alt="BCIS Logo" /></Col>
                </Row>
            </Container>
        </div>
    )
}