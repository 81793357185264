import React from 'react'
import Card from 'react-bootstrap/Card'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import TextBlock from './TextBlock'

export default function MemberProfile(props) {

    const cmsEndPoint = process.env.REACT_APP_CMS_END_POINT

    return (
        <Card className="memberCard">
            <Card.Header>
                <Row>
                    <Col><img src={`${cmsEndPoint}${props.headshotImage.url}`} alt={props.headshotImage.alternativeText} className="memberHeadShot" /></Col>
                    <Col className="memberCompanyLogo"><img src={`${cmsEndPoint}${props.companyLogo.url}`} alt={props.companyLogo.alternativeText} /></Col>
                </Row>
            </Card.Header>
            <Card.Body>
                <Card.Title>{props.memberName}</Card.Title>
                <Card.Subtitle>{props.jobTitle}</Card.Subtitle>
                <Card.Text className="memberBio"><TextBlock content={props.bio} /></Card.Text>
            </Card.Body>
            <Card.Footer>
                <img src="/images/LI-In-Bug.png" alt="LinkedIn Logo" className="linkedInLogo" />
                <a href={props.linkedInProfile}>view profile</a>
            </Card.Footer>
        </Card>
    )
}