import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { QueryClientProvider, QueryClient } from "react-query";
// import { ReactQueryDevtools } from 'react-query/devtools'
import App from "./App";
import SteeringGroup from "./SteeringGroup";
import WorkingGroup from "./WorkingGroup";
import Documents from "./Documents";
import Insights from "./Insights";
import KeepInformed from "./KeepInformed";
import Article from "./Article";
import EntityConsultation from "./EntityConsultation";
import UserAgreement from "./UserAgreement";
import UserGuide from "./UserGuide";
import Statistics from "./Statistics";

const queryClient = new QueryClient();

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <Router>
        <Route exact path="/" component={App} />
        <Route path="/steering-group" component={SteeringGroup} />
        <Route path="/working-group" component={WorkingGroup} />
        <Route path="/documents" component={Documents} />
        <Route path="/insights" component={Insights} />
        <Route path="/article/:id" component={Article} />
        <Route path="/keep-informed" component={KeepInformed} />
        <Route path="/entity-section" component={EntityConsultation} />
        <Route path="/user-agreement" component={UserAgreement} />
        <Route path="/user-guide" component={UserGuide} />
        <Route path="/statistics" component={Statistics} />
      </Router>
      {/* <ReactQueryDevtools /> */}
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
