import React from 'react'

export default function ZohoForm(props) {
    return (
        <div id={props.divId} className="contactForm">
            <iframe src={props.iframeSrc} style={JSON.parse(props.iframeStyle)} title={`zohoForm-${props.divId}`} />
            <script type="text/javascript">
                {props.formScript}
            </script>
        </div>
    )
}