import axios from "axios";

const cmsEndPoint = process.env.REACT_APP_CMS_END_POINT;

const homePageEndPoint = `${cmsEndPoint}/${process.env.REACT_APP_CMS_HOME_PAGE}`;
const steeringGroupPageEndPoint = `${cmsEndPoint}/${process.env.REACT_APP_CMS_STEERING_GROUP_PAGE}`;
const workingGroupPageEndPoint = `${cmsEndPoint}/${process.env.REACT_APP_CMS_WORKING_GROUP_PAGE}`;
const documentsPageEndPoint = `${cmsEndPoint}/${process.env.REACT_APP_CMS_DOCUMENTS_PAGE}`;
const keepInformedPageEndPoint = `${cmsEndPoint}/${process.env.REACT_APP_CMS_KEEP_INFORMED_PAGE}`;
const insightsPageEndPoint = `${cmsEndPoint}/${process.env.REACT_APP_CMS_INSIGHTS_PAGE}`;
const entityConsultationEndPoint = `${cmsEndPoint}/${process.env.REACT_APP_CMS_ENTITY_CONSULTATION_PAGE}`;
const userAgreementPageEndPoint = `${cmsEndPoint}/${process.env.REACT_APP_CMS_USER_AGREEMENT_PAGE}`;
const userGuidePageEndPoint = `${cmsEndPoint}/${process.env.REACT_APP_CMS_USER_GUIDE_PAGE}`;
const statisticsPageEndPoint = `${cmsEndPoint}/${process.env.REACT_APP_CMS_STATISTICS_PAGE}`;

export async function fetchHomePageContent() {
  const { data } = await axios.get(homePageEndPoint);
  return data;
}

export async function fetchSteeringGroupPageContent() {
  const { data } = await axios.get(steeringGroupPageEndPoint);
  return data;
}

export async function fetchWorkingGroupPageContent() {
  const { data } = await axios.get(workingGroupPageEndPoint);
  return data;
}

export async function fetchDocumentsPageContent() {
  const { data } = await axios.get(documentsPageEndPoint);

  if (data.Documents.some((e) => e.Archived === true)) {
    console.log("SHOW ARCHIVED SECTION");
  } else {
    console.log("DO NOT SHOW ARCHIVED SECTION");
  }
  return data;
}

export async function fetchKeepInformedPageContent() {
  const { data } = await axios.get(keepInformedPageEndPoint);
  return data;
}

export async function fetchInsightsPageContent() {
  const { data } = await axios.get(insightsPageEndPoint);
  return data;
}

export async function fetchUserAgreementPageContent() {
  const { data } = await axios.get(userAgreementPageEndPoint);
  return data;
}

export async function fetchUserGuidePageContent() {
  const { data } = await axios.get(userGuidePageEndPoint);
  return data;
}

export async function fetchStatisticsPageContent() {
  const { data } = await axios.get(statisticsPageEndPoint);
  return data;
}

export async function fetchEntityConsultationContent() {
  const { data } = await axios.get(entityConsultationEndPoint);
  return data;
}
