import React from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'

export default function ArticleItem(props) {

    const cmsEndPoint = process.env.REACT_APP_CMS_END_POINT

    return (
        <Card className="articleCard" key={props.key}>
            <Row>
                <Col className='articleCardImage'><Link to={`/article/${props.articleId}`}><img src={`${cmsEndPoint}${props.articleThumbnail}`} alt='Article Thumbnail' /></Link></Col>
                <Col className='articleCardContent'>
                    <Row>{new Date(props.datePublished).toDateString()}</Row>
                    <Row><Link to={`/article/${props.articleId}`}>{props.articleTitle}</Link></Row>
                    <Row>&nbsp;</Row>
                    <Row>{props.strapline}</Row>
                </Col>
            </Row>
        </Card>
    )

}