import React from 'react'
import ReactGA from 'react-ga'
import { Card, Col, Row } from 'react-bootstrap'

export default function Doc(props) {

    const cmsEndPoint = process.env.REACT_APP_CMS_END_POINT

    function handleDownloadClick(docName) {
        ReactGA.event({
            category: 'Documents',
            action: 'Download',
            label: docName
        })
    }

    return (
        <Card className="documentCard" key={props.key}>
            <Row>
                <Col className="documentCardNormalWidth documentIcon"><img src='/images/documentIcon.png' alt='Document Icon' /></Col>
                <Col className="documentCardTripleWidth">{props.documentName}</Col>
                <Col className="documentCardNormalWidth">{props.version}</Col>
                <Col className="documentCardDoubleWidth">{props.authorHeadshotUrl && <img src={`${cmsEndPoint}${props.authorHeadshotUrl}`} alt={props.authorHeadshotAltText} className="authorHeadShot" />}{props.author}</Col>
                <Col className="documentCardNormalWidth documentCol"><a href={`${cmsEndPoint}${props.fileUrl}`} onClick={() => handleDownloadClick(props.documentName)} download>Download</a></Col>
            </Row>
        </Card>
    )

}