import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import MemberProfile from './MemberProfile'

export default function Members(props) {

    function renderMembers() {

        const finalJSX = []
        let columns = []

        props.data.forEach((member, index) => {
            columns.push(
                <Col className="" key={`col-${index.toString()}`}>
                    <MemberProfile
                        memberName={member.Name}
                        jobTitle={member.JobTitle}
                        bio={member.Bio}
                        linkedInProfile={member.LinkedInProfileUrl}
                        headshotImage={member.HeadshotImage}
                        companyLogo={member.CompanyLogo}
                        key={index.toString()}
                    />
                </Col>
            )
            if ((index + 1) % 2 === 0) {
                // Add an empty row to provide some spacing. This ensures that the cards are of the same height using css while maintaining
                // the ability to add a margin of sorts. This should be managed purely by CSS so one to look into further.
                finalJSX.push(<Row className="memberProfiles" key={`row-${index.toString()}`}>{columns}</Row>)
                finalJSX.push(<Row key={`row-${(index + 1).toString()}`}><Col>&nbsp;</Col><Col>&nbsp;</Col></Row>)
                columns = []
            }
        })

        if (columns.length > 0) {
            finalJSX.push(<Row className="" key="memberProfilesFinalRow">{columns}{columns.length === 1 && <Col></Col>}</Row>)
        }

        return finalJSX
    }
    return (
        <Container>
            {renderMembers()}
        </Container>
    )
}