import React, { useEffect } from "react";
import ReactGA from "react-ga";
import { useQuery } from "react-query";
import MasterLayout from "./components/layout/MasterLayout";
import { fetchStatisticsPageContent } from "./utils/cmsData";
import TextBlock from "./components/TextBlock";

export default function Statistics() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  const { data, error, isError, isLoading } = useQuery(
    "userGuide",
    fetchStatisticsPageContent
  );

  if (isLoading) return "Loading...";
  if (isError) return { error };

  function renderStatistics() {
    return (
      <div>
        <h1>{data.PageTitle}</h1>
        <TextBlock content={data.Body} />
      </div>
    );
  }

  return <MasterLayout>{renderStatistics()}</MasterLayout>;
}
