import React, { useEffect } from "react";
import ReactGA from "react-ga";
import { useQuery } from "react-query";
import MasterLayout from "./components/layout/MasterLayout";
import TextBlock from "./components/TextBlock";
import Members from "./components/Members";
import { fetchWorkingGroupPageContent } from "./utils/cmsData";

export default function WorkingGroup() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  const { data, error, isError, isLoading } = useQuery(
    "workingGroup",
    fetchWorkingGroupPageContent
  );

  if (isLoading) return "Loading...";
  if (isError) return { error };

  return (
    <MasterLayout>
      {data && data.MainHeading && data.MainHeading.length > 0 && (
        <h1>{data.MainHeading}</h1>
      )}
      {data &&
        data.IntroductionContent &&
        data.IntroductionContent.length > 0 && (
          <TextBlock content={data.IntroductionContent} />
        )}
      {data &&
        data.WorkingGroup1Heading &&
        data.WorkingGroup1Heading.length > 0 && (
          <h2>{data.WorkingGroup1Heading}</h2>
        )}
      {data && data.WG1MemberProfiles && data.WG1MemberProfiles.length > 0 && (
        <Members data={data.WG1MemberProfiles} />
      )}
      {data &&
        data.WorkingGroup2Heading &&
        data.WorkingGroup2Heading.length > 0 && (
          <h2>{data.WorkingGroup2Heading}</h2>
        )}
      {data && data.WG2MemberProfiles && data.WG2MemberProfiles.length > 0 && (
        <Members data={data.WG2MemberProfiles} />
      )}
      {data &&
        data.WorkingGroup3Heading &&
        data.WorkingGroup3Heading.length > 0 && (
          <h2>{data.WorkingGroup3Heading}</h2>
        )}
      {data && data.WG3MemberProfiles && data.WG3MemberProfiles.length > 0 && (
        <Members data={data.WG3MemberProfiles} />
      )}
    </MasterLayout>
  );
}
