import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import Doc from "./Doc";

export default function DocumentList(props) {
  function renderDocuments() {
    const finalJSX = [];

    props.data.forEach((document, index) => {
      if (document.Archived === props.archived) {
        finalJSX.push(
          <Row>
            <Col key={`col-${index.toString()}`}>
              <Doc
                documentName={document.DocumentName}
                version={document.Version}
                author={document.AuthorName}
                fileUrl={document.File.url}
                authorHeadshotUrl={
                  document.AuthorHeadShot &&
                  document.AuthorHeadShot.ImageAsset &&
                  document.AuthorHeadShot.ImageAsset.url
                }
                authorHeadshotAltText={
                  document.AuthorHeadShot &&
                  document.AuthorHeadShot.AlternativeText &&
                  document.AuthorHeadShot.AlternativeText
                }
                updated={document.File.updated_at}
                key={props.data.id}
              />
            </Col>
          </Row>
        );
      }
    });

    return finalJSX;
  }

  return (
    <Container>
      <Card className="documentCardHeader">
        <Card.Header>
          <Row>
            <Col className="documentCardNormalWidth"></Col>
            <Col className="documentCardTripleWidth">Document name</Col>
            <Col className="documentCardNormalWidth">Version</Col>
            <Col className="documentCardDoubleWidth">Author</Col>
            <Col className="documentCardNormalWidth"></Col>
          </Row>
        </Card.Header>
      </Card>
      {renderDocuments()}
    </Container>
  );
}
